import moment from "moment";
import { MySpaceChartData } from "../dto/dashboard";
import { t } from "@lingui/macro";
import { getCountryCodeFromLocale } from "../utils";

export const getMySpaceChartData = (
  data?: MySpaceChartData,
  locale?: string
) => {
  if (!data) {
    return {
      categories: [],
      series: [],
    };
  }

  const databarTranslation = [
    {
      label: "net_billing",
      translation: t`Net Billing`,
      type: "bar",
      color: "#256EFF",
    },
    {
      label: "net_margin",
      translation: t`Net Margin`,
      type: "bar",
      color: "#9747FF",
    },
    {
      label: "net_margin_percentage",
      translation: t`Net Margin %`,
      type: "line",
      color: "#17C3B2",
    },
    {
      label: "assignated_leads",
      translation: t`Assignated Leads`,
      type: "bar",
    },
    {
      label: "generated_budgets",
      translation: t`Generated Budgets`,
      type: "bar",
    },
    {
      label: "won_leads",
      translation: t`Won Leads`,
      type: "bar",
    },
  ];

  const newSeries = data.series.map((serie) => {
    const databar = databarTranslation.find((bar) => bar.label === serie.name);
    if (databar) {
      return {
        ...serie,
        type: databar.type,
        name: databar.translation,
        color: databar.color,
        borderWidth: 0,
        borderRadius: 50,
        borderSkipped: false,
      };
    }
    return serie;
  });
  const newCategories = data.categories.map((category) => {
    const date = moment(category + "01")
      .locale(locale ? getCountryCodeFromLocale(locale) : "es")
      .format("MMM")
      .replace(".", "");
    return date.charAt(0).toUpperCase() + date.slice(1);
  });
  return {
    categories: newCategories,
    series: newSeries,
  };
};
