import { GeoUnit } from "./geounit";
import { Organization } from "./organizations";
import { Carrier } from "./carriers";
import { Category, Comment, Product, Supplier } from "./inventory";
import { t } from "@lingui/macro";
import { CreatedBySupplierUser, IMap } from "@/lib/dto/leads";

export interface Customer {
  id: number;
  name: string;
  address: string;
  phone: string;
  email: string;
  organization_id?: number;
  organization?: Organization;
  dni: string;
}

export interface NewOrganization {
  name?: string;
  phone?: string;
  address?: string;
  dni?: string;
  economic_activity?: string;
}

export interface NewCustomer {
  name?: string;
  phone?: string;
  email?: string;
  address?: string;
  dni?: string;
  organization_id?: number;
}

export interface DetailCarrier {
  supplier_carrier: Carrier;
  supplier_carrier_id: number;
  supplier_carrier_car_plate: string;
  kind: string;
  date: string;
}

export interface OrderDetail {
  id?: number;
  uuid: string | null;
  order_id?: number | null;
  start_date: string;
  end_date: string;
  days: number;
  carriers: DetailCarrier[];
  price?: number;
  supplier_product: Product | null;
  supplier_product_id: number | null;

  productError?: string;
  sameDriver: boolean;
}

export interface Order {
  id?: number;
  supplier_internal_id?: number;
  customer: Customer;
  customer_id: number;
  details_count?: number;
  geo_unit: GeoUnit;
  geo_unit_id: number;
  details: OrderDetail[];
  address_street: string;
  address_street_number: string;
  address_reference: string;
  start_date?: string;
  end_date?: string;
  is_closed?: boolean;
  kind: string;
  total_price?: number;
  availability: boolean[];
  owned?: boolean;
  lead_id?: number;
}

export interface ShortOrder {
  customer?: {
    id?: number;
    name: string;
  };
  customer_id: number;
  supplier_product_ids: number[];
  start_date: string;
  end_date: string | null;
  total_price?: number;
  details?: OrderDetail[];
}

export interface OrderLeaseDetailItem {
  id?: number;
  order_id: number;
  category_id?: number;
  supplier_product_id?: number;
  price: number;
  minimum_hours?: number;
  supplier_price: number;
  supplier_id: number;
  start_date: string;
  end_date: string;
  slot_uuid: string;
  supplier_product?: Product;
  transportation_price?: number;
  supplier_transportation_id?: number;
  supplier_transportation_price?: number;
}

export interface OrderLeaseDetail {
  is_editable?: boolean;
  category: Category;
  details: OrderLeaseDetailItem[];
  supplier_product?: Product;
}

export interface OrderLease {
  supplier: Supplier;
  products: OrderLeaseDetail[];
  supplier_transportation?: Carrier;
}

export interface AvailabilityResponse {
  is_available: boolean;
  next_available_date: string | null;
}

export enum FilterKind {
  BY_WITHDRAWAL = "by_withdrawal",
  BY_DELIVERY = "by_delivery",
}

export interface OrderInvoice {
  id: number;
  number: number;
  net_price: number;
  tax_price: number;
  total_price: number;
  file: string;
}

export interface OrderExtension {
  id: number;
  name: string;
}

export interface CreateOrderInvoice {
  number?: number;
  net_price?: number;
  tax_price?: number;
  total_price?: number;
  file?: File | null;
  fileRemote?: string | null;
}

export interface InactiveDetailItem {
  id: number;
  uuid: string;
  order_id: number;
  supplier_product_id: number;
  supplier_product: Product;
  supplier: Supplier;
  category: Category;
  price: number;
  supplier_price: number;
  start_date: string;
  end_date: string;
  reason: string;
  closed: boolean;
  replaced: boolean;
}

export interface closeDetail {
  order_detail_id: number;
  end_date: string;
  reason?: string;
}

export interface closeDetailReq {
  order_id: number;
  close_order_details: closeDetail[];
}

export interface OrderAttachmentDetail {
  id: number;
  order_id: number;
  kind: string;
  invoice_id: number | null;
  payment_method: string | null;
  file: string;
  name?: string;
}

export interface OrderAttachment {
  name: string;
  attachments: OrderAttachmentDetail[];
}

export interface CreateOrderAttachment {
  order_id?: number;
  kind: string;
  invoice_id?: number;
  payment_method?: string;
  file?: File | null;
  fileRemote?: string | null;
}

export enum OrderAttachmentKind {
  BUDGET = "budget",
  BUY_ORDER = "buy_order",
  DELIVERY_GUIDE = "delivery_guide",
  PAYMENT = "payment",
  SUPPLIER_INVOICE = "supplier_invoice",
  OTHER = "other",
}

export enum OrderPaymentMethod {
  CHECK = "check",
  TRANSFER = "transfert",
  CREDIT_CARD = "credit_card",
  DEBIT_CARD = "debit_card",
}

export interface EventFilters {
  search?: string;
  start_date?: string;
  end_date?: string;
}

export interface OrderEvent {
  id?: number;
  kind: string;
  description?: string;
  start_date: string;
  end_date: string;
  order_detail_id: number;
  order_detail: Order;
  order_id?: number;
}

export interface OrderGantt {
  order_id: number;
  order_detail_id: number;
  order_supplier_internal_id: number;
  events: OrderEvent[];
  customer: Customer;
  supplier: Supplier;
}

export interface Gantt {
  supplier_product_internal_id: number;
  supplier_product_name: string;
  details: OrderGantt[];
}

export interface EditInternalNumber {
  supplier_internal_id?: number;
}

export enum OperationTaskKind {
  CALL = "call",
  MEETING = "meeting",
  EMAIL = "email",
  TRACKING = "tracking",
  DEADLINE = "deadline",
  CREATE_BUDGET = "create_budget",
  SEND_BUDGET = "send_budget",
  OTHER = "other",
}

export interface ContactInfo {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  organization_name: string;
  organization_dni: string;
}

export interface OperationTask {
  contact_info?: ContactInfo;
  id?: number;
  kind: OperationTaskKind;
  date: string;
  notes?: string | null;
  assigned_to_supplier_user_ids?: number[];
  assigned_to_supplier_users?: CreatedBySupplierUser[];
  done?: boolean;
  object_id?: number;
  content_type?: string;
}

export interface OperationTaskFilters extends IMap {
  kind?: string;
  supplier_user_ids?: number[];
  start_date?: string;
  end_date?: string;
}

export enum OperationTaskContentType {
  ORDER = "orders_context | Order",
  LEAD = "orders_context | Lead",
}

export function getOrderTaskKindTranslated(kind: OperationTaskKind): string {
  switch (kind) {
    case OperationTaskKind.CALL:
      return t`Call`;
    case OperationTaskKind.MEETING:
      return t`Meeting`;
    case OperationTaskKind.EMAIL:
      return t`Email`;
    case OperationTaskKind.TRACKING:
      return t`Tracking`;
    case OperationTaskKind.DEADLINE:
      return t`Deadline`;
    case OperationTaskKind.CREATE_BUDGET:
      return t`Create budget`;
    case OperationTaskKind.SEND_BUDGET:
      return t`Send budget`;
    default:
      return t`Other`;
  }
}

export interface OperationTaskPayload {
  order_id?: number;
  lead_id?: number;
  task_id?: number;
  payload: OperationTask;
}

export enum NonOperationalDayDefinedBy {
  CUSTOMER = "Customer",
  RENDALOMAQ = "Rendalomaq",
}

export interface NonOperationalDay {
  id: number;
  date: string;
  defined_by: NonOperationalDayDefinedBy;
}

export interface OperabilityDayUsage {
  label: string;
  value: number;
  accumulated: number;
  total: number;
}

export interface OperabilityDay {
  order_detail_id: number;
  date: string;
  usage?: OperabilityDayUsage;
  operability?: NonOperationalDay;
  comment?: Comment;
}

export interface Operability {
  id?: number;
  order_id: number;
  start_date: string;
  end_date: string;
  supplier_product_id: number;
  supplier_product: Product;
  days?: OperabilityDay[];
}

export enum OperabilityKind {
  NON_OPERABILITY_CLIENT = "non_operability_client",
  NON_OPERABILITY_RENDALOMAQ = "non_operability_rendalomaq",
  OPERABILITY = "operability",
}

export interface NonOperationalDayPayload {
  defined_by: string;
  date: string;
  order_detail_id: number;
}

export interface OperabilityFilter {
  start_date?: string;
  end_date?: string;
  supplier_product_ids?: number[];
}

export interface OperabilityMachine {
  date: string;
  day: OperabilityDay;
  comment?: Comment;
}
